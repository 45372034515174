import React from 'react';

const Footer = () => (
  <div className="mx-auto bg-primary">
    <div className="max-w-[90rem] mx-auto py-16 px-4">
      <p className="text-white">
        &copy; 2024 All rights reserved.
      </p>
    </div>
  </div>
);

export default Footer;
