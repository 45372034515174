import React from 'react';
import PropTypes from 'prop-types';

import {
  Footer,
  Header,
} from './Layout';

import '../styles/base.css';
import '../styles/fluid.css';

const Layout = ({ children, location }) => (
  <>
    <header id="header">
      <Header />
    </header>
    <main id="main">
      {children}
    </main>
    <footer id="footer" className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <Footer />
    </footer>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
};

export default Layout;
