import React from 'react';
import { Link } from 'gatsby';

import { GenericLogo2 } from '../Misc';

const Header = () => (
  <div className="mx-auto relative bg-primary">
    <div className="max-w-[90rem] mx-auto pt-4 pb-8 px-4">
      <h1 className="text-3xl font-bold text-white">Proposed 6-story Riverdale Lookout Development</h1>
      {/*
      <Link
        to="#"
        className="flex"
      >
        <span className="sr-only">Generic Logo, return to homepage</span>
        <GenericLogo2 />
      </Link>
      */}
    </div>
  </div>
);

export default Header;
